import { extendTheme } from "@chakra-ui/react"
const theme = extendTheme({
    colors:{
        secondary:"#2196f3",
        primary:"#3f51b5"
    },
    components:{
        Button:{
            variants:{
                primary:{
                    backgroundColor: "primary",
                    _hover:{},
                    _focus:{},
                    color:"white"
                },
                secondary:{
                    backgroundColor: "secondary",
                    _hover:{},
                    _focus:{},
                    color:"white"
                }
            }
        }
    }
})

export default theme