import { Box, chakra, Icon } from "@chakra-ui/react";
import { useQueryWithStore } from "react-admin";
import { FiCheckCircle, FiXCircle, FiCircle,FiShoppingBag } from "react-icons/fi"
import DayJS from "dayjs"

function tsToString(ts) {
    let date = DayJS(ts)
    return date.format("DD.MM.YYYY [um] HH:mm:ss")
}

export default function OrderChronik(props) {
    const { record } = props;

    const { loaded, error, data } = useQueryWithStore({
        type: 'getList',
        resource: 'timeline',
        payload: { pagination: { page: 1, perPage: 99999 }, sort: { field: "tsPlanned", order: "DESC" }, filter: { "parent.id": record.id } }
    });

    if (!loaded || !data) {
        return null
    }

    return (
        <>
            <Box m="2">
                <Box as="h2" fontSize="2xl" fontWeight={600}>Chronik</Box>
                <Box display="flex" flex="1" margin="1" width="100%" textAlign="center" flexDir="column" justifyContent="space-between">
                    <Box opacity="1" flexDir="column" alignItems="flex-start" flex="1 0 auto" justifyContent="flex-start" display="flex" position="relative" width="100%">
                        {data.map((tl, index) => {
                            return (
                                <>
                                    <Box display="flex" flexDir="row" alignItems="center">
                                        <Box borderRadius="50%" display="flex" alignItems="center" justifyContent="center" transitionProperty="background,border-color" transitionDuration="normal" width="40px" height="40px" borderWidth="2px" background="#e0e7ef">

                                            <Icon verticalAlign="middle" w="18px" h="18px" strokeWidth="2px" color={tl.status === "error" ? "red.500" : tl.status === "completed" ? "green.500" : "gray.700"} as={tl.status === "error" ? FiXCircle : tl.status === "completed" ? FiCheckCircle : FiCircle} />

                                        </Box>
                                        <Box textAlign="left" alignItems="flex-start" display="flex" flexDir="column" justifyContent="center">
                                            <chakra.span marginInlineStart="2" marginInlineEnd="2" marginTop="0px" opacity="1" color="gray.900" fontWeight="medium" textAlign="center" fontSize="md">
                                                {tl.message}
                                            </chakra.span>
                                        </Box>
                                    </Box>
                                    <Box mt="2" mb="2" flex="1" display="flex" borderColor="gray.200" transitionProperty="border-color" transitionDuration="normal" marginInlineStart="calc(40px / 2)" paddingInlineStart="4" marginInlineEnd="0px" height="auto" alignSelf="stretch" borderTopWidth="0px" minHeight="1.5rem" borderInlineStartWidth="2px">
                                        <chakra.span ml="4">{tl.tsDone ? "Erledigt am " : tl.status === "error" ? "Fehlgeschlagen am " : "Geplant für den "}{tl.tsDone ? tsToString(tl.tsDone) : tsToString(tl.tsPlanned)}</chakra.span>
                                    </Box>
                                </>
                            )
                        })}
                        {data.length > 0 &&
                            <>
                                <Box display="flex" flexDir="row" alignItems="center">
                                    <Box borderRadius="50%" display="flex" alignItems="center" justifyContent="center" transitionProperty="background,border-color" transitionDuration="normal" width="40px" height="40px" borderWidth="2px" background="#e0e7ef">

                                        <Icon verticalAlign="middle" w="18px" h="18px" strokeWidth="2px" as={FiShoppingBag} />

                                    </Box>
                                    <Box textAlign="left" alignItems="flex-start" display="flex" flexDir="column" justifyContent="center">
                                        <chakra.span marginInlineStart="2" marginInlineEnd="2" marginTop="0px" opacity="1" color="gray.900" fontWeight="medium" textAlign="center" fontSize="md">
                                            
                                        </chakra.span>
                                    </Box>
                                </Box>
                                
                            </>

                        }

                    </Box>
                </Box>
            </Box>
        </>
    )
}