import {
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Box,
    Button,
    Center,
    Icon,
    Text
} from '@chakra-ui/react';
import { ImCheckboxChecked } from "react-icons/im"
import { MdCancel } from "react-icons/md"
import { RiErrorWarningLine } from "react-icons/ri"
import { Field, Form, useFormik, FormikProvider } from 'formik';
import { useState } from 'react';
import { useMutation } from 'react-admin';
import * as yup from "yup";
import FormikSelect from '../../util/FormikSelect';
import DisplayProduct from '../DisplayProduct/DisplayProduct';
import EditAdress from '../EditAdress/EditAdress';
import EditContact from '../EditContact/EditContact';

const validationSchema = yup.object({
    weight: yup
        .number().transform((_, value) => {
            if (value.includes('.')) {
                return +value;
            }
            return +value.replace(/,/, '.');
        }).required("Das Gewicht darf nicht leer sein").test(
            "maxDigitsAfterDecimal",
            "Das Gewicht muss voranden sein und darf nicht mehr als 2 Nachkommastellen haben",
            (number) => /^\d+([.,]\d{1,2})?$/.test(number))
});

export const FulfillmentDialog = (props) => {
    const [open, setOpen] = useState(false)
    const { status } = props.record
    console.log(props.record)
    const handleClose = () => {
        setOpen(false)
    }
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            weight: null,
            package: "lg"
        },
        onSubmit: (values) => {
            alert(JSON.stringify(values, null, 2));
        },
    });
    const [completeOrder, { loading }] = useMutation({
        type: 'update',
        resource: 'order',
        payload: { id: props.record.id, data: { status: "completed" } }
    });
    let showButton = true;
    switch (status) {
        case "pending":
        case "processing":
        case "onHold":
            showButton = true;
            break;
        default:
            showButton = false;
            break;
    }
    return (
        <>
            {showButton ? <Button onClick={() => setOpen(true)} variant="secondary">Als ausgeführt markieren</Button> : ""}

            <Modal
                isOpen={open}
                onClose={handleClose}
                size={"6xl"}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Fulfillment</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormikProvider value={formik}>
                            <Box display="flex">
                                <Box flexGrow="1" p="2" >
                                    <Box maxW="90%" shadow="lg">
                                        <DisplayProduct {...props} />
                                    </Box>
                                </Box>
                                <Box flexGrow="1" float="right" p="2">
                                    <EditContact name="Kontaktinformationen" display record={props.record} {...props} />
                                    <EditAdress name="Lieferadresse" type="shippingAdress" display record={props.record} {...props} />
                                </Box>
                            </Box>
                            <Form
                                onSubmit={formik.handleSubmit}
                            >
                                <Box borderTop="1px solid gray" mt="2" mb="2" display="flex">
                                    <Box flexGrow="1">
                                        <Box w="50%" minW="210px" p="2">
                                            <Box >
                                                <Field name='weight'>
                                                    {({ field, form }) => (
                                                        <FormControl isInvalid={form.errors.weight && form.touched.weight}>
                                                            <FormLabel htmlFor='weight'>Gewicht (kg)</FormLabel>
                                                            <Input {...field} id='weight' placeholder='Gewicht in kg' />
                                                            <FormErrorMessage>{form.errors.weight}</FormErrorMessage>
                                                        </FormControl>
                                                    )}
                                                </Field>
                                            </Box>

                                            <Box pt="2">
                                                <Field label="Verpackung (l x b x h)" name="package" component={FormikSelect}>
                                                    <option value={"lg"}>32cm x 40cm x 35cm</option>
                                                    <option value={"xl"}>40cm x 50cm x 55cm</option>
                                                </Field>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box flexGrow="1">
                                        <Center h="100%" >
                                            <Button type="submit" disabled={!formik.isValid || !formik.dirty} variant="outline" float="right">Versandlabel erstellen</Button>
                                        </Center>
                                    </Box>
                                </Box>
                            </Form>

                            <Box w="100%" borderTop="1px solid grey" mt="2">
                                <Button float="right" type="primary" variant="primary" mt="2" isLoading={loading} onClick={async () => {

                                    await completeOrder();
                                    setOpen(false)
                                }}>Fulfillment abschließen</Button>
                            </Box>

                        </FormikProvider>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export const FulfillmentHeader = (props) => {
    const status = props.record?.status

    let text, clz = undefined
    let icon = RiErrorWarningLine;
    switch (status) {
        case 'canceled':
        case 'failed':
            clz = "red.600"
            text = "Bestellung storniert abgeschlossen"
            icon = MdCancel

            break;
        case 'completed':
            clz = "green.600"
            text = "Bestellung abgeschlossen"
            icon = ImCheckboxChecked
            break;
        case 'pending':
        case 'processing':
        case 'onHold':
        default:
            clz = "orange.400"
            text = "Nicht ausgeführt"
    }


    return (
        <Box display="flex" fontSize="3xl" alignItems="center" mb="2">
            <Icon mr="2" color={clz} as={icon} />
            <Text fontSize="2xl">{text}</Text>
        </Box>
    )
}