import {
    Edit,
    Toolbar,
    FormWithRedirect,
} from 'react-admin';
import { Card, CardContent } from '@material-ui/core';

import EditAdress from '../../components/EditAdress/EditAdress';
import EditContact from '../../components/EditContact/EditContact';
import { FulfillmentHeader, FulfillmentDialog } from '../../components/Fulfillment/Fulfillment';

import DisplayProduct from '../../components/DisplayProduct/DisplayProduct';
import { Box } from '@chakra-ui/react';
import DisplayPayment, { PaymentHeader } from '../../components/DisplayPayment/DisplayPayment';
import OrderChronik from '../../components/Chronik/OrderChronik';

export const OrderEdit = props => {
    return (

        <Edit title={`Bestellung #${props.id}`} component="div" {...props} >
            <OrderForm />
        </Edit>
    )
};
const OrderForm = (props) => {
    return (
        <FormWithRedirect
            {...props}

            render={(formProps) => (

                <form>

                    <Box display={{ md: 'block', lg: 'flex' }} flexDir={"column"}>
                        <Box display="flex" flex={2} pb="3" mb="4">
                            <Box flex={2} pr="3" pl="3" pt="1" border="1.1px solid lightgrey" shadow="lg" bg="white">
                                <FulfillmentHeader   {...props} />

                                <DisplayProduct {...formProps} />
                                <Box display="flex" justifyContent="end" pt="1">
                                    <FulfillmentDialog  {...props} />
                                </Box>



                            </Box>
                            <Box
                                flex={1}
                                ml={{ xs: 0, lg: '1em' }}
                                mt={{ xs: '1em', lg: 0 }}
                            >
                                <Box shadow="lg">
                                    <EditContact name="Kontaktinformationen" record={formProps.record} {...props} />
                                    <EditAdress name="Lieferadresse" type="shippingAdress" record={formProps.record} {...props} />
                                    <EditAdress name="Rechungsadresse" type="billingAdress" record={formProps.record} {...props} />
                                </Box>
                            </Box>
                        </Box>

                        <Card>
                            <PaymentHeader {...props} />
                            <CardContent>
                                <DisplayPayment {...props} />
                            </CardContent>
                        </Card>
                        <Box mt="5" border="1.1px solid lightgrey" shadow="lg">
                            <OrderChronik {...props} />
                        </Box>
                    </Box>
                    <Toolbar
                        record={formProps.record}
                        basePath={formProps.basePath}
                        undoable={true}
                        invalid={formProps.invalid}
                        handleSubmit={formProps.handleSubmit}
                        saving={formProps.saving}
                        resource="customers"
                    />
                </form>

            )}
        />
    );
};
