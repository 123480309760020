import {
  Edit,
  TextInput,
  BooleanInput,
  DateInput,
  ArrayInput,
  SimpleFormIterator,
  NumberInput,
  ReferenceInput,
  SelectInput,
  TabbedForm,
  FormTab,
  required,
  ReferenceArrayInput,
  SelectArrayInput,
  minValue,
  maxValue,
  AutocompleteInput
} from "react-admin";

import TextField2 from '@material-ui/core/TextField';
import { NoMultibleDefaultOptions } from "../../util/validator";
import { withStyles } from "@material-ui/core/styles";
import RichCodeInput from "../../components/CodeEditor/CodeEditor";
import { Box, Divider, Grid, Typography } from "@material-ui/core";

import { useField } from 'react-final-form';

const ProductTitle = ({ record }) => {
  return <span>{record ? record.name : "Produkt"}</span>;
};


const variantType = "standard";


const PriceInput = props => {
  const { source, ...rest } = props;


  const {
    input: { onChange, value },
    meta: { touched, error },
  } = useField(source);

  return (
    <span>
      <NumberInput
        variant={variantType}
        label="Default Preis"
        validate={[minValue(0)]}
        {...rest}
        source={source}
      />

      <TextField2
        name={source}
        value={round(value * 1.19)}
        label="blub"
        //onChange= {onChange}
        onChange={event => {
          if (!isNaN(event.target.value)) 
          { 
            onChange(round(event.target.value / 119 * 100)) 
          } else {
              
          }
        
        }}
      error={!!(touched && error)}
      helperText={touched && error}
      />

    </span>
  );
};

const round = (num) => {
  return Math.round((num + Number.EPSILON) * 100) / 100;
}


export const ProductEdit = withStyles({ card: { overflow: "initial" } })(
  (props) => (

    <Edit {...props} title={<ProductTitle />}>
      <TabbedForm variant={variantType}>
        <FormTab label="Allgemein">
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={1}
            style={{ width: "100%" }}
          >
            <Grid container xs={12} spacing={8}>
              <Grid item xs={3}>
                <TextInput
                  variant={variantType}
                  source="sku"
                  validate={required()}
                  label="SKU"
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <TextInput
                  variant={variantType}
                  source="brand"
                  label="Marke"
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <TextInput
                  variant={variantType}
                  source="ean"
                  label="EAN"
                  fullWidth
                />
              </Grid>
            </Grid>

            <Grid container xs={12} spacing={8}>
              <Grid item xs={3}>
                <TextInput
                  variant={variantType}
                  source="name"
                  label="Name"
                  validate={required()}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <TextInput variant={variantType} source="permalink" fullWidth />
              </Grid>
            </Grid>
            <Grid container xs={12} spacing={8}>
              <Grid item xs={3}>
                <SelectInput
                  fullWidth
                  variant={variantType}
                  source="publish.status"
                  label="Status"
                  validate={required()}
                  choices={[
                    { id: "incomplete", name: "Incomplete" },
                    { id: "draft", name: "Draft" },
                    { id: "published", name: "Published" },
                  ]}
                />
              </Grid>
            </Grid>

            <Grid container xs={12} spacing={8}>
              <Grid item xs={12} style={{ padding: "48px 0px 0px 32px" }}>
                <Typography variant="h5">Maße</Typography>
                <Divider />
              </Grid>
              <Grid item xs={3}>
                <NumberInput
                  variant={variantType}
                  source="shippingDimensions.weight"
                  label="Gewicht in g"
                  validate={minValue(0)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <NumberInput
                  variant={variantType}
                  source="shippingDimensions.height"
                  label="Höhe in cm"
                  fullWidth
                  validate={minValue(0)}
                />
              </Grid>
              <Grid item xs={3}>
                <NumberInput
                  variant={variantType}
                  source="shippingDimensions.length"
                  label="Länge in cm"
                  validate={minValue(0)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <NumberInput
                  variant={variantType}
                  source="shippingDimensions.width"
                  label="Breite in cm"
                  validate={minValue(0)}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container xs={12} spacing={8}>
              <Grid item xs={12} style={{ padding: "48px 0px 0px 32px" }}>
                <Typography variant="h5">Bilder</Typography>
                <Divider />
              </Grid>
              <Grid item xs={3}>
                <ArrayInput source="images" style={{ width: "80%" }}>
                  <SimpleFormIterator>
                    <TextInput
                      source="name"
                      variant={variantType}
                      label="Name"
                      fullWidth
                    />

                    <TextInput
                      source="url"
                      type="url"
                      variant={variantType}
                      label="URL"
                      fullWidth
                    />
                  </SimpleFormIterator>
                </ArrayInput>
              </Grid>
            </Grid>
            <Grid container xs={12} spacing={8}>
              <Grid item xs={12} style={{ padding: "48px 0px 0px 32px" }}>
                <Typography variant="h5">Tags</Typography>
                <Divider />
              </Grid>
              <Grid item xs={3}>
                <ReferenceArrayInput source="tags" reference="tag" label="">
                  <SelectArrayInput optionText="id" variant={variantType} />
                </ReferenceArrayInput>
              </Grid>
            </Grid>
          </Grid>
        </FormTab>

        <FormTab label="SEO">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
            style={{ width: "85%" }}
          >
            <Box style={{ display: "block" }}>
              <TextInput
                  variant={variantType}
                  source="seo.pageTitle"
                  label="PageTitle"
                  style={{ display: "block" }}
                />
               <TextInput
                  variant={variantType}
                  source="seo.description"
                  label="Beschreibung für die Suchmaschine"
                  style={{ display: "block" }}
                />
            </Box>
    
          </Box>
        </FormTab>

        <FormTab label="Set-Info">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
            style={{ width: "85%" }}
          >
            <Box style={{ display: "block" }}>
              <BooleanInput source="setInfo.moc" label="MOC" />
              <NumberInput
                variant={variantType}
                label="Teileanzahl"
                source="setInfo.partcount"
                validate={[minValue(0)]}
                style={{ display: "block" }}
              />
              <NumberInput
                variant={variantType}
                label="Erscheinungsjahr"
                source="setInfo.releaseYear"
                validate={[minValue(1940)]}
                style={{ display: "block" }}
              />
              <DateInput
                variant={variantType}
                label="Erscheinungsdatum"
                source="setInfo.releaseDate"
                style={{ display: "block" }}
              />
              <NumberInput
                variant={variantType}
                label="Bauzeit"
                source="setInfo.buildDurationInHours"
                validate={minValue(0)}
                style={{ display: "block" }}
              />
              <NumberInput
                variant={variantType}
                label="Bauspaß"
                source="setInfo.satisfaction"
                validate={[minValue(0), maxValue(6)]}
                style={{ display: "block" }}
              />
              <NumberInput
                variant={variantType}
                label="Schwierigkeit"
                source="setInfo.complexity"
                validate={[minValue(0), maxValue(6)]}
                style={{ display: "block" }}
              />
            </Box>
            <ArrayInput
              source="setInfo.instruction"
              label="Anleitungen"
              style={{ width: "45%" }}
            >
              <SimpleFormIterator>
                <TextInput
                  source="name"
                  label="Name"
                  variant={variantType}
                  fullWidth
                />
                <TextInput
                  source="url"
                  type="url"
                  label="URL"
                  variant={variantType}
                  fullWidth
                />
              </SimpleFormIterator>
            </ArrayInput>
          </Box>
        </FormTab>

        <FormTab label="Preis">
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={1}
            style={{ width: "100%" }}
          >
            <Grid container xs={12} spacing={1}>
              <Grid item>
                <NumberInput
                  variant={variantType}
                  source="price.amount"
                  label="Default Preis"
                  validate={[minValue(0)]}
                />

              </Grid>
              <Grid item>
                <SelectInput
                  variant={variantType}
                  source="price.currency"
                  label="Währung"
                  choices={[{ id: "€", name: "€" }]}
                  defaultValue="€"
                />
              </Grid>
            </Grid>

            <Grid container xs={12} spacing={1}>
              <Grid item>
                <NumberInput
                  variant={variantType}
                  source="setInfo.priceInfo.RRP.amount"
                  label="Regulärer Retail Preis"
                  validate={[minValue(0)]}
                />
              </Grid>
              <Grid item>
                <SelectInput
                  variant={variantType}
                  source="setInfo.priceInfo.RRP.currency"
                  label="Währung"
                  choices={[{ id: "€", name: "€" }]}
                  defaultValue="€"
                />
              </Grid>
            </Grid>

            <Grid container xs={12} spacing={1}>
              <Grid item>
                <NumberInput
                  variant={variantType}
                  source="setInfo.priceInfo.POV.amount"
                  label="Part Out Value"
                  validate={minValue(0)}
                />
              </Grid>
              <Grid item>
                <SelectInput
                  variant={variantType}
                  source="setInfo.priceInfo.POV.currency"
                  label="Währung"
                  choices={[{ id: "€", name: "€" }]}
                  defaultValue="€"
                />
              </Grid>
            </Grid>

            <Grid container xs={12} spacing={1}>
              <Grid item>
                <NumberInput
                  variant={variantType}
                  source="setInfo.priceInfo.replacementValue.amount"
                  label="Replacement Value"
                />
              </Grid>
              <Grid item xs={3}>
                <TextInput
                  variant={variantType}
                  style={{ width: "100%" }}
                  source="setInfo.priceInfo.replacementValue.url"
                  label="Link zum Shop"
                  type="url"
                />
              </Grid>
            </Grid>
            <Grid container xs={12} spacing={1}>
              <Grid item>
                <SelectInput
                  allowEmpty
                  variant={variantType}
                  source="taxCode"
                  label="Steuer-Typ"
                  choices={[
                    { id: "default", name: "default" },
                    { id: "reduced price", name: "reduced price" },
                    { id: "tax free", name: "tax free" },
                  ]}
                  defaultValue="default"
                />
              </Grid>
            </Grid>
          </Grid>
        </FormTab>
        <FormTab label="brickInfo">
          <TextInput source="brickInfo.colorId" label="Farb-ID" />
          <TextInput source="brickInfo.colorName" label="Farb-Name" />
          <NumberInput source="brickInfo.bulkAmount" label="Bulk Amount" />
          <SelectInput
            allowEmpty
            variant={variantType}
            source="brickInfo.type"
            label="Typ"
            choices={[
              { id: "MINIFIG", name: "MINIFIG" },
              { id: "PART", name: "PART" },
              { id: "SET", name: "SET" },
              { id: "BOOK", name: "BOOK" },
              { id: "GEAR", name: "GEAR" },
              { id: "CATALOG", name: "CATALOG" },
              { id: "INSTRUCTION", name: "INSTRUCTION" },
              { id: "UNSORTED_LOT", name: "UNSORTED_LOT" },
              { id: "ORIGINAL_BOX", name: "ORIGINAL_BOX" },
            ]}
          />
        </FormTab>

        <FormTab label="Optionen">
          <ArrayInput source="options" validate={NoMultibleDefaultOptions}>
            <SimpleFormIterator>
              <ReferenceInput source="option" reference="option" label="Option">
                <SelectInput optionText="name" />
              </ReferenceInput>

              {/* <ReferenceInput source="option" reference="option" label="Option">
                <FunctionField label="Preis" src="price" render={totalRender} addLabel={true}/>
              </ReferenceInput> */}

              <AutocompleteInput label="Zustand" source="state" choices={[
                { id: 'unselected', name: 'Option ist abgewählt und kann geändert werden' },
                { id: 'selected', name: 'Option ist angewählt und kann geändert werden' },
                { id: 'required', name: 'Option ist angewählt und kann nicht abgewählt werdenMuss Bestellt werden' },
                { id: 'disabled', name: 'Option ist abgewählt und kann nicht angewählt werden' },
                { id: 'rented', name: 'Vermietet' }
            ]} />



              <PriceInput
                variant={variantType}
                source="price.amount"
                label="Preis"
                validate={minValue(0)}
              />

              <SelectInput
                variant={variantType}
                source="price.currency"
                label="Währung"
                choices={[{ id: "€", name: "€" }]}
                defaultValue="€"
              />

              <NumberInput

                source="price.offer.amount"
                label="Angebots Preis"
              />
              <SelectInput

                source="price.offer.currency"
                label="Währung"
                choices={[{ id: "€", name: "€" }]}
                defaultValue="€"
              />
              <DateInput label="Angebots Start" source="price.offer.tsStart" />
              <DateInput label="Angebots Ende" source="price.offer.tsEnd" />


            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="Beschreibung">
          <RichCodeInput source="description.productpage" />
        </FormTab>
      </TabbedForm>
    </Edit>
  )
);
