import { FormControl, FormErrorMessage, FormLabel, propNames, Select } from "@chakra-ui/react";

const FormikSelect = (props) => {
    const { name, value } = props.field;
    const { setFieldValue } = props.form;

    return (
        <FormControl isInvalid={props.form.errors[name] && props.form.touched[name]}>
            <FormLabel htmlFor={name}>{props.label}</FormLabel>
            <Select
                name={name}
                value={value}
                onChange={e => {
                    setFieldValue(name, e.target.value);
                }}
            >
                {props.children}
            </Select>
            <FormErrorMessage>{props.form.errors[name]}</FormErrorMessage>
        </FormControl>

    );
};

export default FormikSelect 