import { Datagrid, DateField, List, ListBase, Pagination, TextField } from "react-admin"


export const TimelineList = props => {
    
    console.log(props)
    let datum = new Date(Date.now())
    return(
        <List filter={{ "tsPlanned_gte": datum.toISOString()}} sort={{field: "tsPlanned",order:"DESC"}} {...props}>
            <Datagrid>
                <TextField source="message" label="Nachricht"/>
                <DateField source="tsPlanned" options={{year: 'numeric', month: '2-digit', day: '2-digit' }} label="Geplante Ausführung"/>
            </Datagrid>
            
        </List>
    )
};