import * as React from "react";
import { render } from "react-dom";
import theme from "./util/theme"
import {
  Admin,
  Resource,
  EditGuesser,
  fetchUtils,
  Layout,
  ListGuesser,
} from "react-admin";
import { AuthProvider, useAuth } from "oidc-react";
import authProvider from "./provider/authProvider";
import { ProductList } from "./sites/product/List";
import { OptionList } from "./sites/option/List";
import { ProductEdit } from "./sites/product/Edit";
import { OptionEdit } from "./sites/option/Edit";
import { OptionCreate } from "./sites/option/Create";
import LogoutButton from "./sites/auth/LogoutButton";
import simpleRestProvider from "ra-data-simple-rest";
import InventoryList from "./sites/inventory/List";
import { InventoryCreate } from "./sites/inventory/Create";
import { ProductCreate } from "./sites/product/Create";
import { BookingList } from "./sites/booking/List";
import { BookingEdit } from "./sites/booking/Edit";
import { InventoryEdit } from "./sites/inventory/Edit";
import { TagList } from "./sites/tag/List";
import { TagEdit } from "./sites/tag/Edit";
import { WalletList } from "./sites/wallet/List";
import { WalletEdit } from "./sites/wallet/Edit";
import { OrderList } from "./sites/order/List";
import { OrderEdit } from "./sites/order/Edit";
import { CustomerList } from "./sites/customer/List";
import CustomAppBar from "./components/AppBar/AppBar";
import { ChakraProvider } from '@chakra-ui/react'
import { DiscountruleList } from "./sites/discountrule/List";
import { DiscountcodeList } from "./sites/discountcode/List";
import { DiscountruleEdit } from "./sites/discountrule/Edit";
import { DiscountruleCreate } from "./sites/discountrule/Create";
import { DiscountcodeEdit } from "./sites/discountcode/Edit";
import { TimelineList } from "./sites/timeline/List";

const MyLayout = (props) => <Layout {...props} appBar={CustomAppBar} />;

const uri = window.location;
let redirectUri = "";
if (uri.port !== "") {
  redirectUri = `${uri.protocol}//${uri.hostname}:${uri.port}/`;
} else {
  redirectUri = `${uri.protocol}//${uri.hostname}/`;
}
const oidcConfig = {
  onSignIn: () => {
    window.location.hash = "";
  },
  authority: "https://auth.rebricker.com/auth/realms/rebricker",
  responseType: "code",
  clientId: "rebricker-manager",
  redirectUri: redirectUri,
  autoSignIn: true,
  automaticSilentRenew: true,
  revokeAccessTokenOnSignout: true,
};

const CustomAdminWithKeycloak = () => {
  const auth = useAuth();
  if (auth.userData == null) {
    auth.userManager.signinSilent()
  }
  if (auth.userData == null) {
    return (
      <div>
        <button onClick={() => auth.signIn()}>Login</button>
      </div>
    );
  }
  const httpClient = (authT) => {
    return (url, options = {}) => {
      if (!options.headers) {
        options.headers = new Headers({
          Authorization: "Bearer " + authT.userData.access_token,
        });
      } else {
        options.headers.set(
          "Authorization",
          "Bearer " + authT.userData.access_token
        );
      }
      // add your own headers here
      return fetchUtils.fetchJson(url, options);
    };
  };
  return (
    <Admin
      dataProvider={simpleRestProvider(process.env.REACT_APP_API_URL,
        httpClient(auth)
      )}
      logoutButton={LogoutButton}
      loginPage={false}
      authProvider={authProvider(auth)}
      layout={MyLayout}
    >
      <Resource name="product" list={ProductList} edit={ProductEdit} create={ProductCreate} />
      <Resource name="option" list={OptionList} edit={OptionEdit} create={OptionCreate} />
      <Resource name="inventory" list={InventoryList} edit={InventoryEdit} create={InventoryCreate} />
      <Resource name="booking" list={BookingList} edit={BookingEdit} />
      <Resource name="customer" list={CustomerList} edit={EditGuesser} />
      <Resource name="tag" list={TagList} edit={TagEdit} />
      <Resource name="wallet" list={WalletList} edit={WalletEdit} />
      <Resource name="order" list={OrderList} edit={OrderEdit} />
      <Resource name="discountrule" list={DiscountruleList} edit={DiscountruleEdit} create={DiscountruleCreate}/>
      <Resource name="discountcode" list={DiscountcodeList} edit={DiscountcodeEdit}/>
      <Resource name="timeline" list={TimelineList}/>
    </Admin>
  );
};
render(
  <ChakraProvider theme={theme}>
    <AuthProvider {...oidcConfig}>
      <CustomAdminWithKeycloak />
    </AuthProvider>
  </ChakraProvider>,
  document.getElementById("root")
);
