import {
  List,
  Datagrid,
  TextField,
  DateField,
  SingleFieldList,
  ChipField,
  ArrayField,
  FunctionField,
  ReferenceField,
  EditButton,
} from "react-admin";


const totalRender = entry => {

  if (!entry.value) {
    return `0,00 €`;
  }

  const value = entry.value.total.amount + entry.value.total.tax + entry.value.shipping.amount + entry.value.shipping.tax

  return `${new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value)}`;
}





export const OrderList = (props) => (
  <List {...props} perPage="25">
    <Datagrid rowClick="edit">
      <ReferenceField label="Bestellung" source="id" reference="order">
        <TextField source="id" />
      </ReferenceField>
      <DateField source="createdAt" label="Datum" showTime={true} />
      <FunctionField label="Kunde" render={record => `${record.billingAdress.firstName} ${record.billingAdress.lastName}`} />
      <ArrayField label="Zahlung" source="payment" >
        <SingleFieldList>
          {/* 
          
          https://help.shopify.com/en/manual/orders/order-status
          Authorized
Overdue
Paid
Partially refunded
Partially paid
Payment pending
Refunded
Unpaid
Voided*/}
          <ChipField source="status" />
        </SingleFieldList>


      </ArrayField>
      <ChipField source="status" label="Fulfillment status" />

      <FunctionField label="Gesamt" src="value" render={totalRender} />
      <ArrayField label="Booking" source="positions" >
        <SingleFieldList>
          <ReferenceField source="booking" reference="booking">
            <TextField source="inventory" />
          </ReferenceField>
        </SingleFieldList>
      </ArrayField>
      <EditButton />
    </Datagrid>
  </List>
);
