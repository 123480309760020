import { Box, Table, Tbody, Td, Thead, Tr } from "@chakra-ui/react";
import { Link, useQueryWithStore } from "react-admin";

export default function DisplayProduct(props) {
    const { record } = props

    const { loaded, data: products } = useQueryWithStore(
        {
            type: 'getMany',
            resource: 'product',
            payload: {
                ids: record ? record.positions.map(item => item.product) : [],
            },
        },
        {},
        state => {
            const productIds = record
                ? record.positions.map(item => item.product)
                : [];

            return productIds
                .map(
                    productId =>
                        state.admin.resources.product.data[
                        productId
                        ]
                )
                .filter(r => typeof r !== 'undefined')
                .reduce((prev, next) => {
                    prev[next.id] = next;
                    return prev;
                }, {});
        }
    );

    const { loaded: optionLoaded, data: options } = useQueryWithStore(
        {
            type: 'getMany',
            resource: 'option',
            payload: {
                ids: record ? record.positions.map(item => item.options.map(option => option.option)).flat() : [],
            },
        },
        {},
        state => {
            const ids = record
                ? record.positions.map(item => item.options.map(option => option.option)).flat()
                : [];

            return ids
                .map(
                    id => {

                        return state.admin.resources.option.data[
                            id
                        ]
                    }

                )
                .filter(r => typeof r !== 'undefined')
                .reduce((prev, next) => {
                    prev[next.id] = next;
                    return prev;
                }, {});
        }
    );

    if (!loaded || !optionLoaded || !props.record) return null;
    return (
        <Box border="1px solid lightgrey" mb="1">
        <Table variant="striped" colorScheme='gray' >
            <Thead>
                <Tr>
                    <Td>
                        Produkt
                    </Td>
                    <Td>
                        Anzahl
                    </Td>
                    <Td colSpan={2}>
                        Gesamt
                    </Td>
                </Tr>
            </Thead>
            <Tbody>
                {record?.positions.map(
                    (item,index) => {
                        return (
                            products[item.product] && (
                                <>
                                    <Tr key={`${item.product}_${index}`} >
                                        <Td >
                                            <Link to={`/product/${item.product}`}>
                                                {products[item.product].name}
                                            </Link>
                                        </Td>
                                        <Td >
                                            {item.quantity.value}
                                        </Td>
                                        <Td >
                                            {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(item.price.amount + item.price.tax)}
                                        </Td>
                                    </Tr>
                                    {item.options.map(
                                        (option,index) =>
                                            options[option.option] && (
                                                <Tr key={`${option._id}_${index}`}>
                                                    <Td>
                                                        <Link to={`/option/${option.option}`}>
                                                            {options[option.option].name}
                                                        </Link>
                                                    </Td>
                                                    <Td>

                                                    </Td>
                                                    <Td >
                                                        {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
                                                            option.price.amount
                                                        )}
                                                    </Td>
                                                </Tr>)
                                    )}
                                </>
                            ))
                    }
                )}
                
            </Tbody>
        </Table>
        </Box >
    )
}